import { CVButton } from "@constituentvoice/cv-elements/web";
import styled from "styled-components";

import { Popover } from "../../../components/aria/Popover";

export const PrivacyButton = styled(CVButton)``;

export const LabelText = styled.span`
  ${(p) => p.theme.typography.label.large};
  color: ${(p) => p.theme.colors.onSurface};
`;

export const FilterMenuPopover = styled(Popover)`
  border-radius: 0.25rem;
  box-shadow:
    0px 2.75px 9px 0px rgba(0, 0, 0, 0.19),
    0px 0.25px 3px 0px rgba(0, 0, 0, 0.04);
  width: 80%;
  max-width: 23.75rem;
  overflow: hidden;

  & > ul {
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: 20rem;
    overflow-y: auto;

    & > li:not(:last-of-type) {
      border-bottom: 0.0625rem solid ${(p) => p.theme.colors.outlineVariant};
    }
  }
`;

export const FiltersMenu = styled.ul`
  flex: 1;
`;

export const MenuItem = styled.li`
  padding: 1rem;

  &:hover {
    background: ${(p) => p.theme.colors.surfaceAlpha[10]};
  }
`;

export const MenuItemText = styled.span`
  ${(p) => p.theme.typography.label.large};
`;
