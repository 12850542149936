import type { PhotosFilter } from "@constituentvoice/cv-elements/web";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useButton } from "@react-aria/button";
import { AriaMenuProps, useMenuTrigger } from "@react-aria/menu";
import type { MenuTriggerProps } from "@react-stately/menu";
import { useMenuTriggerState } from "@react-stately/menu";
import { useMemo, useRef } from "react";

import { useAnchorElementStyles } from "../../../hooks/use-anchor-element-styles";

import {
  FilterMenuPopover,
  FiltersMenu,
  LabelText,
  MenuItem,
  MenuItemText,
  PrivacyButton,
} from "./PhotoPrivacyFilter.style";

const EVENT_FILTERS: Array<{ value: PhotosFilter; label: string }> = [
  {
    value: "EventPhotos",
    label: "Show All Event Photos",
  },
  {
    value: "MeetingPhotos",
    label: "Show All My Meeting Photos",
  },
  {
    value: "MyPhotos",
    label: "Show All My Photos",
  },
];

const MEETING_FILTERS: Array<{ value: PhotosFilter; label: string }> = [
  {
    value: "MyPhotos",
    label: "Show All My Photos",
  },
  {
    value: "MeetingPhotos",
    label: "Show All Meeting Photos",
  },
];

interface PrivacyFilterProps extends AriaMenuProps<unknown>, MenuTriggerProps {
  currentFilter: PhotosFilter;
  setCurrentFilter: (filter: PhotosFilter) => void;
  forEvent?: boolean;
}

export const PhotoPrivacyFilter = (
  props: Omit<PrivacyFilterProps, "children">,
) => {
  const { currentFilter, setCurrentFilter } = props;
  const filters = props.forEvent ? EVENT_FILTERS : MEETING_FILTERS;

  const ref = useRef<HTMLButtonElement>(null);

  const state = useMenuTriggerState(props);
  const { menuTriggerProps } = useMenuTrigger(
    {
      isDisabled: state.isOpen,
    },
    {
      ...state,
      open: () => {
        state.open();
      },
      toggle: () => {
        state.toggle(null);
      },
    },
    ref,
  );
  const { buttonProps } = useButton(menuTriggerProps, ref);
  const popOverStyle = useAnchorElementStyles(state.isOpen, ref);

  const filterLabel = useMemo(() => {
    return filters.find((it) => it.value === currentFilter)?.label;
  }, [currentFilter, filters]);

  const filterList = useMemo(() => {
    return filters.filter((it) => it.value !== currentFilter);
  }, [currentFilter, filters]);

  return (
    <>
      <PrivacyButton ref={ref} {...buttonProps}>
        <LabelText>{filterLabel}</LabelText>
        <FontAwesomeIcon icon={faCaretDown} />
      </PrivacyButton>
      {state.isOpen && (
        <FilterMenuPopover style={popOverStyle} state={state} triggerRef={ref}>
          <FiltersMenu>
            {filterList.map(({ value, label }) => (
              <MenuItem
                onClick={() => {
                  setCurrentFilter(value);
                  state.close();
                }}
              >
                <MenuItemText>{label}</MenuItemText>
              </MenuItem>
            ))}
          </FiltersMenu>
        </FilterMenuPopover>
      )}
    </>
  );
};
