import type { EnvironmentalVars } from "@constituentvoice/cv-elements/web";

export const ENVIRONMENTS = {
  DEVELOPMENT: "development",
  STAGING: "staging",
  PRODUCTION: "production",
  LOCAL: "local",
  TRAINING: "training",
  SANDBOX: "sandbox",
};

export const ENVIRONMENT =
  import.meta.env?.VITE_BUILD_ENVIRONMENT ?? ENVIRONMENTS.LOCAL;

export const PARENT_CODE = "advocacyassoc";
export const CLIENT_CODE = "advocacyday";

export const config: EnvironmentalVars = {
  ENVIRONMENT,
  CLIENT_LIST_URL: {
    DEVELOPMENT: `https://lambda.advocacyday.dev/list-clients`,
    STAGING: `https://lambda.advocacyday.live/list-clients`,
    PRODUCTION: `https://lambda.advocacyday.net/list-clients`,
    TRAINING: `https://lambda.advocacy.day/list-clients`,
    LOCAL: `http://localhost:5129/v5/clients/`,
    SANDBOX: `https://lambda.advocacyaction.us/list-clients`,
  },
  GQL_URL: {
    DEVELOPMENT: `advocacyday.dev/graphql`,
    STAGING: `advocacyday.live/graphql`,
    PRODUCTION: `advocacyday.net/graphql`,
    TRAINING: `advocacy.day/graphql`,
    LOCAL: `localhost:5129/graphql`,
    SANDBOX: `advocacyaction.us/graphql`,
  },
  API_URL: {
    DEVELOPMENT: `advocacyday.dev/v5`,
    STAGING: `advocacyday.live/v5`,
    TRAINING: `advocacy.day/v5`,
    PRODUCTION: `advocacyday.net/v5`,
    LOCAL: "localhost:5129/v5",
    SANDBOX: "advocacyaction.us/v5",
  },
  // TODO: Migrate to check for iOS or Android and change the string
  GOOGLE_MAPS: {
    iosApiKey: import.meta.env?.VITE_GOOGLE_MAPS_IOS_API_KEY ?? "",
    androidApiKey: import.meta.env?.VITE_GOOGLE_MAPS_ANDROID_API_KEY ?? "",
  },
  COGNITO: {
    DEVELOPMENT: {
      USER_POOL_ID: "us-east-1_1OBBcyx8y",
      USER_POOL_CLIENT_ID: "6krccgk1gvh858qcj55e7dqtlj",
    },
    STAGING: {
      USER_POOL_ID: "us-east-1_qYHUT6kVA",
      USER_POOL_CLIENT_ID: "5sm5hruv22o93l2j3vd3t8bv5u",
    },
    PRODUCTION: {
      USER_POOL_ID: "us-east-1_aNOqRmEmE",
      USER_POOL_CLIENT_ID: "m58j65c3b8k35ac1rl7r61ctl",
    },
    TRAINING: { USER_POOL_ID: "", USER_POOL_CLIENT_ID: "" },
    LOCAL: {
      USER_POOL_ID: "us_east_1_arobsearudsbeadursbe",
      USER_POOL_CLIENT_ID: "aeroubsaursbdaruoeb",
    },
    SANDBOX: { USER_POOL_ID: "", USER_POOL_CLIENT_ID: "" },
  },
  VERSION_CODE: "1.0.0",
};
