import {
  EventPhotosScreenUI,
  useEventPhotos,
} from "@constituentvoice/cv-elements/web";
import { useNavigate } from "react-router-dom";

import { useBuildClientRoute } from "../../hooks/use-build-client-route";
import { PhotoPrivacyFilter } from "../MeetingPhotos/components/PhotoPrivacyFilter";

export const EventPhotosScreenView = () => {
  const {
    currentFilter,
    photosData,
    photosLoading,
    setCurrentFilter,
    uploadEventPhotos,
    uploadMeetingPhotos,
    isUploadingPhotos,
    meetings,
    currentMeeting,
    setCurrentMeeting,
    refetchPhotos,
  } = useEventPhotos();

  const navigate = useNavigate();

  const buildRoute = useBuildClientRoute();

  const onLegislatorArrowPressed = () => {
    navigate(buildRoute(`/legislators-home/${currentMeeting?.legislator?.id}`));
  };

  const onBackPressed = () => {
    navigate(-1);
  };

  return (
    <EventPhotosScreenUI
      onBackPressed={onBackPressed}
      photosData={photosData}
      photosLoading={photosLoading}
      privacyFilterComponent={
        <PhotoPrivacyFilter
          currentFilter={currentFilter}
          setCurrentFilter={setCurrentFilter}
          forEvent
        />
      }
      uploadEventPhotos={uploadEventPhotos}
      uploadMeetingPhotos={uploadMeetingPhotos}
      isUploadingPhotos={isUploadingPhotos}
      meetings={meetings}
      currentMeeting={currentMeeting}
      setCurrentMeeting={setCurrentMeeting}
      refetchPhotos={refetchPhotos}
      onLegislatorArrowPressed={onLegislatorArrowPressed}
    />
  );
};
