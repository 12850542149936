import { RouteObject } from "react-router";

import { ReactRouterErrorBoundary } from "../components/ErrorBoundary/ReactRouterErrorBoundary.view";
import { ClientListLayout } from "../layouts/client-list-layout";
import { ServiceListLayout } from "../layouts/service-code-layout";
import { TopNavLayout } from "../layouts/TopNavLayout";
import { ValidateAuthLayout } from "../layouts/validate-auth-layout";
import { ActionCenterHomeView } from "../screens/ActionCenterHome/ActionCenterHome.view";
import { AttendeesScreenView } from "../screens/Attendees/AttendeesScreen.view";
import { BillDetailScreen } from "../screens/BillDetail/BillDetailScreen.view";
import { ChooseYourGroupView } from "../screens/ChooseYourGroup/choose-your-group.view";
import { DirectoryHomeScreenView } from "../screens/DirectoryHomeScreen/DirectoryHomeScreen.view";
import { EventBillsScreen } from "../screens/EventBills";
import { EventDocumentsScreenView } from "../screens/EventDocuments/EventDocumentsScreen.view";
import { EventPhotosScreenView } from "../screens/EventPhotos/EventPhotosScreen.view";
import { EventSurveyScreenView } from "../screens/EventSurvey/EventSurveyScreen.view";
import { FAQScreenView } from "../screens/FAQ/FAQ.view";
import { HomeScreenView } from "../screens/Home/HomeScreen.view";
import { LegislatorBillsScreenView } from "../screens/LegislatorBillsScreen";
import { LegislatorsDetailsScreenView } from "../screens/Legislators/LegislatorDetails";
import { LegislatorsHomeScreenView } from "../screens/Legislators/LegislatorsHome/LegislatorsHomeScreen.view";
import { LobbyDaySelectionScreen } from "../screens/LobbyDay";
import { MeetingDetailScreenView } from "../screens/MeetingDetail";
import { MeetingDocumentsScreenView } from "../screens/MeetingDocuments/MeetingDocumentsScreen.view";
import { MeetingPhotosScreenView } from "../screens/MeetingPhotos";
import { MeetingSurveyScreenView } from "../screens/MeetingSurvey";
import { MessagesHomeScreenView } from "../screens/MessagesHomeScreen/MessagesHomeScreen.view";
import { MoreHomeScreenView } from "../screens/MoreHome/MoreHomeScreen.view";
import { MyNotesScreenView } from "../screens/MyNotes/MyNotes.view";
import { PasswordlessLogin } from "../screens/PasswordlessLogin/PasswordlessLogin.view";
import { SettingsScreenView } from "../screens/Settings/SettingsScreen.view";
import { SupportScreenView } from "../screens/Support/Support.view";
import { SupportScreenPublicView } from "../screens/SupportPublic/SupportPublic.view";
import { TalkingPointsScreenView } from "../screens/TalkingPointsScreen/TalkingPointsScreen.view";
import { UserProfileScreenView } from "../screens/UserProfile/UserProfileScreen.view";
import { VoteDetailScreenView } from "../screens/VoteDetail/VoteDetail.view";

export const routes: RouteObject[] = [
  {
    path: "/",
    element: <TopNavLayout />,
    errorElement: <ReactRouterErrorBoundary />,
    children: [
      { index: true, element: <PasswordlessLogin />, path: "login" },
      {
        path: "support",
        element: <SupportScreenPublicView />,
      },
      {
        element: <ValidateAuthLayout />,
        children: [
          {
            index: true,
            element: <ChooseYourGroupView />,
          },
          {
            path: "/:serviceCode",
            element: <ServiceListLayout />,
            children: [
              {
                path: "/:serviceCode/:eventId",
                element: <ClientListLayout />,
                children: [
                  { index: true, element: <HomeScreenView /> },
                  {
                    path: "/:serviceCode/:eventId/meeting/:meetingId",
                    element: <MeetingDetailScreenView />,
                  },
                  {
                    path: "/:serviceCode/:eventId/meeting/talking-points/:meetingId",
                    element: <TalkingPointsScreenView />,
                  },
                  {
                    path: "/:serviceCode/:eventId/meeting/legislator-bills/:meetingId",
                    element: <LegislatorBillsScreenView />,
                  },
                  {
                    path: "/:serviceCode/:eventId/meeting/:meetingId/survey",
                    element: <MeetingSurveyScreenView />,
                  },
                  {
                    path: "/:serviceCode/:eventId/meeting/:meetingId/photos",
                    element: <MeetingPhotosScreenView />,
                  },
                  {
                    path: "/:serviceCode/:eventId/meeting/:meetingId/attendees",
                    element: <AttendeesScreenView />,
                  },
                  {
                    path: "/:serviceCode/:eventId/legislators-home",
                    element: <LegislatorsHomeScreenView />,
                  },
                  {
                    path: "/:serviceCode/:eventId/legislators-home/:legislatorId",
                    element: <LegislatorsDetailsScreenView />,
                  },
                  {
                    path: "/:serviceCode/:eventId/more",
                    element: <MoreHomeScreenView />,
                  },
                  {
                    path: "/:serviceCode/:eventId/lobby-day",
                    element: <LobbyDaySelectionScreen />,
                  },
                  {
                    path: "/:serviceCode/:eventId/event-bills",
                    element: <EventBillsScreen />,
                  },
                  {
                    path: "/:serviceCode/:eventId/event-bills/:billId",
                    element: <BillDetailScreen />,
                  },
                  {
                    path: "/:serviceCode/:eventId/event-bills/:billId/votes/:voteId",
                    element: <VoteDetailScreenView />,
                  },
                  {
                    path: "/:serviceCode/:eventId/meeting/:meetingId/documents",
                    element: <MeetingDocumentsScreenView />,
                  },
                  {
                    path: "/:serviceCode/:eventId/event/:eventId/documents",
                    element: <EventDocumentsScreenView />,
                  },
                  {
                    path: "/:serviceCode/:eventId/profile/:userId",
                    element: <UserProfileScreenView />,
                  },
                  {
                    path: "/:serviceCode/:eventId/event/survey",
                    element: <EventSurveyScreenView />,
                  },
                  {
                    path: "/:serviceCode/:eventId/directory",
                    element: <DirectoryHomeScreenView />,
                  },
                  {
                    path: "/:serviceCode/:eventId/notifications",
                    element: <MessagesHomeScreenView />,
                  },
                  {
                    path: "/:serviceCode/:eventId/action-center",
                    element: <ActionCenterHomeView />,
                  },
                  {
                    path: "/:serviceCode/:eventId/settings",
                    element: <SettingsScreenView />,
                  },
                  {
                    path: "/:serviceCode/:eventId/user-support",
                    element: <SupportScreenView />,
                  },
                  {
                    path: "/:serviceCode/:eventId/more/my-notes",
                    element: <MyNotesScreenView />,
                  },
                  {
                    path: "/:serviceCode/:eventId/more/faq",
                    element: <FAQScreenView />,
                  },
                  {
                    path: "/:serviceCode/:eventId/photos",
                    element: <EventPhotosScreenView />,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
