import {
  MeetingPhotosScreenUI,
  useMeetingPhotos,
} from "@constituentvoice/cv-elements/web";
import { useNavigate, useParams } from "react-router-dom";

import { useBuildClientRoute } from "../../hooks/use-build-client-route";
import { useAppTitle } from "../../hooks/use-client-title";

import { PhotoPrivacyFilter } from "./components/PhotoPrivacyFilter";

export interface IRouteMeetingPhotos {
  meetingId: number | string;
}

export const MeetingPhotosScreenView = () => {
  const { meetingId } = useParams<
    keyof IRouteMeetingPhotos
  >() as IRouteMeetingPhotos;

  useAppTitle("Meeting Photos");

  const {
    currentFilter,
    photosData,
    photosLoading,
    setCurrentFilter,
    meeting,
    meetingIsLoading,
    isUploadingPhotos,
    uploadPhotos,
    refetchPhotos,
  } = useMeetingPhotos(Number(meetingId));

  const navigate = useNavigate();

  const buildRoute = useBuildClientRoute();

  const onLegislatorArrowPressed = () => {
    navigate(buildRoute(`/legislators-home/${meeting?.legislator?.id}`));
  };

  return (
    <MeetingPhotosScreenUI
      meeting={meeting}
      meetingLoading={meetingIsLoading}
      photos={photosData}
      photosLoading={photosLoading}
      onBackPressed={() => {
        navigate(-1);
      }}
      privacyFilterComponent={
        <PhotoPrivacyFilter
          currentFilter={currentFilter}
          setCurrentFilter={setCurrentFilter}
        />
      }
      uploadPhotos={uploadPhotos}
      isUploadingPhotos={isUploadingPhotos}
      refetchPhotos={refetchPhotos}
      onLegislatorArrowPressed={onLegislatorArrowPressed}
    />
  );
};
