import {
  DisplayErrorMessagesIfPresent,
  UserProfileScreenUI,
  useUserProfileV5,
} from "@constituentvoice/cv-elements/web";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useAppTitle } from "../../hooks/use-client-title";

import { HiddenAvatarInput } from "./UserProfileScreen.styles";

interface UserProfileScreenViewProps {
  userId: number | string;
}

export const UserProfileScreenViewV5 = () => {
  const { userId } = useParams<
    keyof UserProfileScreenViewProps
  >() as UserProfileScreenViewProps;

  const navigate = useNavigate();
  const location = useLocation();

  const {
    userProfileDetails,
    editState,
    setEditState,
    about,
    setAbout,
    cellPhone,
    setCellPhone,
    email,
    setEmail,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    organization,
    setOrganization,
    title,
    setTitle,
    errors,
    setErrors,
    pictureSource,
    isCurrentUser,
    showSubmit,
    addGalleryPhotoError,
    addNewPhotoError,
    submitChanges,
    submitChangesError,
    invalidPhone,
    invalidEmail,
    userProfileDetailsLoading,
    editProfile,
    uploadPhoto,
    inputPhotoRef,
    DiscardChanges,
    userProfileError,
    showUserEmail,
    showUserPhone,
    showUserTitle,
    showUserOrganization,
    onPromptAccountDelete,
    removeAccount,
  } = useUserProfileV5({ userId: userId });

  useEffect(() => {
    if (location?.state?.editProfileDefault) {
      setEditState(true);
    }
    return;
    /* eslint-disable-next-line */
  }, []);

  useAppTitle(
    userProfileDetails?.user.firstName && userProfileDetails?.user.lastName
      ? `${userProfileDetails?.user.firstName} ${userProfileDetails?.user.lastName}`
      : "User Profile",
  );

  const onClickBackButton = () => {
    navigate(-1);
  };

  if (userProfileDetailsLoading) return <p>Loading...</p>;

  return (
    <>
      <DisplayErrorMessagesIfPresent
        errors={[
          { name: "Fetching this user", val: userProfileError },
          {
            name: "Adding a Photo from Gallery",
            val: addGalleryPhotoError,
          },
          { name: "Adding a New Photo", val: addNewPhotoError },
          { name: "Submitting your Changes", val: submitChangesError },
        ]}
      />
      <UserProfileScreenUI
        removeAccount={() => {
          try {
            removeAccount();
          } catch (error) {
            console.error(error);
          }
        }}
        isCurrentUser={isCurrentUser}
        user={userProfileDetails!.user}
        pictureSource={pictureSource}
        editState={editState}
        firstName={firstName}
        lastName={lastName}
        title={title}
        about={about}
        organization={organization}
        email={email}
        errors={errors}
        cellPhone={cellPhone}
        editProfile={editProfile}
        setFirstName={setFirstName}
        setLastName={setLastName}
        setTitle={setTitle}
        setOrganization={setOrganization}
        setAbout={setAbout}
        setEmail={setEmail}
        setErrors={setErrors}
        setCellPhone={setCellPhone}
        invalidEmail={invalidEmail}
        invalidPhone={invalidPhone}
        submitChanges={submitChanges}
        setEditState={setEditState}
        showSubmit={showSubmit}
        inputPhotoRef={inputPhotoRef}
        discardChanges={DiscardChanges}
        onClickBackButton={onClickBackButton}
        displayBackButton={
          !!(location?.state?.from?.pathname.split("/")[2] === "directory") ||
          !!(location?.state?.from?.pathname.split("/")[2] === "meeting")
        }
        showUserEmail={showUserEmail}
        showUserPhone={showUserPhone}
        showUserTitle={showUserTitle}
        showUserOrganization={showUserOrganization}
        onPromptAccountDelete={onPromptAccountDelete}
      />
      <HiddenAvatarInput
        ref={(ref) => {
          inputPhotoRef.current = ref;
        }}
        type={"file"}
        onChange={(event) => {
          uploadPhoto(event);
        }}
        accept={"image/png, image/gif, image/jpeg"}
      />
    </>
  );
};
