import {
  NotificationsProvider,
  ThemeProvider,
  getCachedAuthData,
  initGeocoder,
  setEnv,
  store,
  useAppDispatch,
  useAppSelector,
  useAuthenticator,
  useCurrentClient,
} from "@constituentvoice/cv-elements/web";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Amplify } from "aws-amplify";
import { useEffect } from "react";
import { Provider as ReduxProvider } from "react-redux";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import { ErrorBoundary } from "./components/ErrorBoundary/ErrorBoundary.view";
import { GlobalStyles } from "./constants/global-styles";
import { config } from "./env";
import { useClientFavicon } from "./hooks/use-client-favicon";
import { routes } from "./navigators";

const queryClient = new QueryClient();

const router = createBrowserRouter(routes);

const { COGNITO, ...environmentVariables } = config;

const cognitoConfig =
  COGNITO[
    environmentVariables.ENVIRONMENT.toUpperCase() as keyof typeof COGNITO
  ];

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: cognitoConfig?.USER_POOL_ID || "",
      userPoolClientId: cognitoConfig.USER_POOL_CLIENT_ID || "",
    },
  },
});

const AppBase = () => {
  // useSetupForRefreshOnFocus();
  // useMessagingToken();

  const client = useCurrentClient();

  const dispatch = useAppDispatch();
  const env = useAppSelector((state) => state.env.env);

  const { authStatus } = useAuthenticator();

  useEffect(() => {
    void Promise.all([dispatch(setEnv(environmentVariables))]).then(() => {
      // Check if the user has logged in already
      return dispatch(getCachedAuthData());
    });
  }, [dispatch]);

  // useFirebaseMessaging();

  useEffect(() => {
    initGeocoder(config.GOOGLE_MAPS.androidApiKey);
  }, []);

  useClientFavicon();

  if (!env || authStatus === "configuring") return <p>Loading...</p>;

  return (
    <ThemeProvider mainColor={client?.templateString}>
      <GlobalStyles />
      <RouterProvider router={router} />
    </ThemeProvider>
  );
};

export const App = () => {
  return (
    <ErrorBoundary>
      <ReduxProvider store={store}>
        <QueryClientProvider client={queryClient}>
          <NotificationsProvider>
            <AppBase />
          </NotificationsProvider>
        </QueryClientProvider>
      </ReduxProvider>
    </ErrorBoundary>
  );
};
